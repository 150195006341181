<template>
  <div class="repairedOrder">
    <div class="title">报修流程</div>
    <div class="content" v-if="report.id" :class="{maxContent:showFooter}">
      <div class="header">
        <span class="order textEllipsis"
        >工单编号{{ report.serialNumber }}</span
        >
        <span class="status" v-html="formatStatus(report.status)"></span>
      </div>
      <div class="name">
        <span class="categoryName textEllipsis"
        >{{ report.categoryName }}报障</span
        >
        <span class="timeDuration"
        >报障时间:{{ formatTimeDurationToSecond(report.reportTime) }}</span
        >
      </div>
      <div style="margin-top: 15px;">
        <orderItem
            class="orderItem"
            v-for="(item, index) in list"
            :key="index"
            :dataInfo="item"
        ></orderItem>
      </div>
    </div>
    <KcEmpty v-else></KcEmpty>
    <div class="footer"
         v-if="showFooter"
    >
      <el-button
          class="cancel"
          round
          @click="reportCancel"
      >
        取消报修工单
      </el-button>
      <el-button
          class="remindReport"
          round
          :disable="remindDisable"
          :loading="remindDisable"
          @click="remindReport"
      >催单
      </el-button
      >
    </div>
  </div>
</template>

<script>
import orderItem from './orderItem'

export default {
  name: 'repairedOrder',
  components: { orderItem },
  data() {
    return {
      id: '',
      serialNumber: '',
      categoryName: '',
      reportTime: '',
      list: [],
      report: {},
      remindDisable: false
    }
  },
  created() {
    this.id = this.$route.query.id
  },
  mounted() {
    this.$nextTick(() => {
      this.reportData()
    })
  },
  computed: {
    showFooter() {
      return this.report.id && this.report.status != 9 && this.report.status != 12 && this.report.status != 13 && this.report.status != 14
    }
  },
  methods: {
    formatStatus(status) {
      switch (status) {
        case 1:
          return `<span style="color:#FF2222;">报修</span>`
        case 2:
          return `<span style="color:#FF2222;">待修</span>`
        case 3:
          return `<span style="color:#FF2222;">催单</span>`
        case 4:
          return `<span style="color:#FF2222;">响应催单</span>`
        case 5:
          return `<span style="color:#60DA47;">已出发</span>`
        case 6:
          return `<span style="color:#FF2222;">维修中</span>`
        case 7:
          return `<span style="color:#60DA47;">维修完成</span>`
        case 8:
          return `<span style="color:#60DA47;">审核通过</span>`
        case 9:
          return `<span style="color:#FF2222;">拒绝报修</span>`
        case 10:
          return `<span style="color:#FF2222;">审核未通过</span>`
        case 11:
          return `<span style="color:#60DA47;">处理通过</span>`
        case 12:
          return `<span style="color:#FF2222;">处理未通过</span>`
        case 13:
          return `<span style="color:#FF2222;">报修取消</span>`
        default:
          return ``
      }
    },
    reportData() {
      this.$showLoading({
        target: '.repairedOrder'
      })
      this.$api.deviceRepair
          .reportDataInfo({
            id: this.id
          })
          .then((res) => {
            localStorage.setItem('allDeviceUpdate', new Date().getTime())
            this.list = res.data.data
            this.report = res.data.report
            this.$hideLoading({
              target: '.repairedOrder'
            })
          })
          .catch((res) => {
            this.$hideLoading({
              target: '.repairedOrder'
            })
          })
    },
    remindReport() {
      this.remindDisable = true
      this.$api.deviceRepair
          .remindReport({
            id: this.id
          })
          .then((res) => {
            this.$message({
              message: '催单成功',
              type: 'success',
              duration: 1000
            })
            this.reportData()
            this.remindDisable = false
          })
          .catch((res) => {
            this.$message({
              message: '催单失败',
              type: 'error',
              duration: 1000
            })
            this.remindDisable = false
          })
    },
    reportCancel() {
      this.$dialog
          .confirm({
            message: '是否取消共享该报修工单？'
          })
          .then(() => {
            this.$api.deviceRepair
                .reportCancel({
                  id: this.id,
                  pass: 0,
                  remark: ''
                })
                .then((res) => {
                  localStorage.setItem('allDeviceUpdate', new Date().getTime())
                  this.$toast.success('已取消')
                  this.reportData()
                })
                .catch((res) => {
                  this.$toast.fail('操作失败')
                })
          })
          .catch(() => {
          })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@styles/variables.scss";

.repairedOrder {
  height: 100%;
  padding: 12px 10px;
  overflow: scroll;

  .title {
    font-weight: bold;
    color: #333333;
    font-size: 1rem;
    padding: 0 0 10px 16px;
  }

  .maxContent {
    max-height: calc(100% - 120px);
  }

  .content {
    padding: 16px 12px;
    background: $background_color2;
    border-radius: 8px;
    overflow: scroll;

    .header {
      font-weight: 500;
      color: #333333;
      font-size: 16px;
      display: flex;

      span {
        display: inline-block;
      }

      .order {
        flex: 1;
      }

      .status {
        width: 90px;
        text-align: right;
      }
    }

    .name {
      font-weight: 400;
      color: #333333;
      font-size: 14px;
      display: flex;
      padding: 8px 0;

      span {
        display: inline-block;
      }

      .categoryName {
        flex: 1;
      }

      .timeDuration {
        text-align: right;
        width: 36%;
      }
    }

  }

  .footer {
    margin: 20px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0px;
    width: 100%;

    .confirm {
      width: 100%;
      background: #3e73fb;
      border-radius: 20px;
      color: #ffffff;
    }

    .remindReport {
      width: 150px;
      border-radius: 20px;
      background: #3e73fb;
      color: #ffffff;
    }

    .cancel {
      border-radius: 20px;
      width: 150px;
      background: #ff2e2e;
      color: #ffffff;
    }
  }
}
</style>